body {
  font-family: 'Roboto';
  overflow-x: hidden;
  background: #ffffff;
}

.app-main {
  flex: 1;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.MuiTypography-root {
    font-family: 'Roboto';
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0ch;
    text-transform: none;
    // color: rgb(118, 118, 118);
    margin: 0px;
    padding: 0px;
    font-variant-ligatures: no-common-ligatures;
    max-width: 100%;
    // overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 40px;
    white-space: normal;
}

.app-content {
  flex: 1;
}

a,
button {
  outline: none !important;
}

.grid {
  display: grid;
}

.place-autocomplete-dropdown {
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  .dropdown-menu {
    width: 100%;
    margin-top: 5px;
  }
  .dropdown-item {
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
  }
}

.body-background {
  background-color: #ffffff;
}

.datepicker-container {
  position: relative;
  max-width: 240px;
  // height: 60px;
  > svg {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.react-datepicker {
  display: flex;
  background: #ffffff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  overflow: hidden;
}

.react-datepicker-wrapper {
  width: 100%;
  display: block;

  .react-datepicker__input-container {
    width: 100%;
    display: block;
  }
}

.react-datepicker__time-container,
.react-datepicker {
  border-color: $border-color;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header {
  // background-color: #fff;
  height: 64px;
  border-color: $border-color;
}

.react-datepicker__time-container {
  width: auto;
  white-space: nowrap;
  .react-datepicker-time__header {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .react-datepicker__time .react-datepicker__time-box {
    width: 100%;
  }
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-weight: 500;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 50%;
}

.react-datepicker__time-list-item--disabled {
  display: none;
}

.text-danger {
  .selected-flag,
  .form-control {
    border-color: $danger;
    border: 1px solid $danger;
  }
  .invalid-feedback {
    display: block;
    font-size: 13px;
  }
}

.tips-wrapper {
  display: flex;
  // align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  .form-check {
    padding-right: 1rem;
    font-size: 16px;
    input[type='radio'],
    input[type='checkbox'] {
      width: 22px;
      height: 22px;
    }
    label {
      margin-right: 5px;
    }
  }
  .custom-check {
    display: flex;
    align-items: center;
    // margin-top: 20px;
    margin-top: 0;
    label {
      margin-right: 5px;
      // margin-top: 10px;
    }
  }
  .tip-input {
    width: 70px;
    height: 40px;
    text-align: left;
  }
  label {
    margin-bottom: 0;
  }
}

.tips {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;

  .tip {
    flex: 1;
    min-width: 56px;
    height: 56px;
    margin-right: 0.5rem;
    line-height: 54px;
    text-align: center;
    background: #ffffff;
    box-shadow: 0 6px 8px 0 rgba(203, 203, 203, 0.3);
    border-radius: 6px;
    font-size: 16px;
    border: 1px solid transparent;
    color: #222933;
    &.active {
      background: rgba(0, 123, 255, 0.1);
      border-color: #007bff;
    }
  }
}

.tips-group {
  margin-right: 0.5rem;

  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #ff6a00;
    border-color: #ff6a00;
  }
}

.tip-input {
  width: 56px;
  height: 56px;
  background: #ffffff;
  box-shadow: 0 6px 8px 0 rgba(203, 203, 203, 0.3);
  border-radius: 6px;
  text-align: center;
  border: none;
  outline: none;
  font-size: 16px;
  &:focus {
    background: rgba(0, 123, 255, 0.1);
    // border-color: #007bff;
    outline: none;
    box-shadow: 0 6px 8px 0 rgba(203, 203, 203, 0.3);
  }
}

.confirm-modal {
  width: 360px;
  margin: auto;
}

.form-control {
  border: none;
  border-radius: 0;
}

.nav-tabs {
  border: none;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border: 1px solid $danger;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.react-datepicker-popper {
  z-index: 10;
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: inherit;
}

.navbar-brand {
  h1 {
    position: relative;
    margin-top: 26px;
  }
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  padding-right: 0;
  padding-left: 0;
}

.modal-invalid {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dollar-icon {
  position: relative;
  left: 17px;
  font-size: 17px;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none !important;
}

@media (min-width: 768px) {
  .confirm-modal {
    width: 460px;
    margin: auto;
  }
}

@media (max-width: 1091px) {
  .form-group {
    label {
      font-size: 16px;
    }
  }
  .tips-group {
    label {
      font-size: 16px;
    }
  }
  .InputContainer .InputElement,
  .ElementsApp,
  .ElementsApp .InputElement {
    font-size: 16px !important;
    background-color: #f4f4f4;
  }
  .container,
  .container-sm,
  .container-md {
    max-width: 100%;
  }
  .app-header {
    .navbar-brand {
      .brand-logo {
        width: 64px;
        height: 64px;
      }
      .sub-title {
        display: none;
      }
      .sub-title-no-logo {
        display: none;
      }
      .title {
        margin-left: 10px;
        font-size: 16px;
        margin-bottom: 0px;
        line-height: 16px;
      }
    }
  }
  .app-footer {
    .border-top {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  .col-lg-6 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .banner-header {
    align-items: center;
    display: flex;
  }
  .sidemenu-bar {
    display: block;
  }
  .brand-logo {
    max-height: 64px;
    max-width: 64px;
  }
  .pickup-section {
    padding: 0 0;
    .tab-content {
      padding: 0 16px;
    }
    .delivery-radio {
      padding: 0 16px;
    }
    .group-date {
      padding: 0 16px;
    }
  }
}

@media (min-width: 1092px) {
  // .goCheckoutButtonWrapper {
  //   display: none;
  // }
  .form-group,
  .tips-group,
  .custom-check {
    label {
      font-size: 18px;
    }
  }
  .footer-section {
    td {
      font-size: 18px;
    }
  }
  .InputContainer .InputElement,
  .ElementsApp,
  .ElementsApp .InputElement {
    font-size: 18px !important;
  }
  .ElementsApp input,
  .InputContainer .InputElement {
    font-size: 18px !important;
  }
  .container,
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1092px;
    margin-left: auto;
    margin-right: auto;
  }
  .app-header {
    .navbar-brand {
      height: 110px;
      position: relative;
      .brand-logo {
        width: 110px;
        height: 110px;
      }
      .sub-title {
        position: absolute;
        bottom: 5px;
        left: 120px;
        font-size: 14px;
        color: #333333;
        clear: left;
      }
      .sub-title-no-logo {
        position: absolute;
        bottom: 5px;
        color: #333333;
      }
      .title {
        margin-top: 48px;
        font-size: 22px;
        margin-bottom: 0px;
        margin-left: 10px;
        line-height: 24px;
      }
    }
  }
  .app-footer {
    .border-top {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  .col-lg-6 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .sidemenu-bar {
    display: none;
  }
  .brand-logo {
    max-height: 110px;
    max-width: 200px;
  }
  .modal-body {
    // overflow-y: hidden;
  }
  .pickup-section {
    padding: 0 16px;
    .tab-content {
      padding: 0 0;
    }
    .group-date {
      padding: 0 0;
    }
  }
}

.ElementsApp,
.ElementsApp .InputElement {
  font-size: 16px !important;
}

.InputElement {
  font-size: 18px !important;
}

.footerLogo {
  width: 70px;
  margin-left: 20px;
}

#black-background {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #21252985;
}

#order-success-page-background {
  // color: #fff;
  a,
  .text-body {
    color: #ff6a00 !important;
  }
  z-index: 9;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
}

.not-found-main {
  flex: 1 1;
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  .not-found-restaurant {
    font-family:  Roboto, Helvetica, sans-serif;
    font-weight: 400;
    font-weight: 300;
    color: #7C7C7C !important;
    font-size: 16px;
    margin-top: 31.98px;
  }

  .app-footer {
    margin-bottom: 0;
  }
}

@media all and (max-width: 425px) {
  .not-found-main {
    .app-footer {
      a {
        font-weight: 600;
        margin-bottom: 5px !important;
        font-size: 12px;
      }
    }
  }
}

.flag-dropdown{
  display: none;
}

.react-tel-input{
  input.form-control {
    padding-left: 10px !important;
    display: block;
    width: 100% !important;
    height: calc(1.5em + 0.75rem + 2px) !important;
    padding: 0.375rem 0.75rem;
    border-color: transparent;
    // border: none !important;
    border-radius: 0 !important;
    font-size: 1rem !important;
    font-weight: 400;
    line-height: 1.5 !important;
    color: #495057;
    background-color: #fff !important;
    background-clip: padding-box !important;
  }
}

.invalid-phone-number {
  color: #dc3545;
}

.invalid-phone-number-border {
  border: 1px solid #dc3545 !important;
}

.invalid-phone-msg {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.MuiRadio-root.Mui-checked, .MuiCheckbox-root.Mui-checked {
  color: #ff6900 !important;
  svg{
    width: 20px;
    height: 20px;
  }
}
.MuiRadio-root, .MuiCheckbox-root {
  svg{
    width: 20px;
    height: 20px;
  }
}
.dordashimg{
  width: 100%;
  height:100%
}

body{
  padding-right: 0 !important;
}