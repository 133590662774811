// Swiper Style.

.swiper-category {
  overflow-y: auto;
  .swiper-slide {
    width: auto !important;
  }

  .categoryItem {
    float: left;
  }

  ul {
    white-space: nowrap;
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    height: 62px;
    width: 100%;
    overflow-x: auto;
  }
  li {
    margin-right: 16px;
    padding:4px 8px;
    cursor: pointer;
  }
  a {
    color: black;
    text-decoration: none;
    text-align: center;
  }
  .active {
    text-decoration: none;
    background: black;
    border-radius: 1000px;
    color: #ffffff;
    padding:4px 8px;
  }
  .active a {
    color: #ffffff;
  }
}
.nav-sidemenu {
  margin-right: 16px;
}

#menu::-webkit-scrollbar {
  display: none;
}

@media all and (max-width: 1024px) {
  .banner-header {
    top: 88px;
  }
  .app-header .navbar {
    height: 84px !important;
  }

  .brand-logo {
    width: 84px !important;
    height: 84px !important;
    max-height: 84px !important;
    max-width: 84px !important;

  }
  .app-header  {
    .navbar-brand{
      .title{
        line-height: 22.4px !important;
        margin-top: 8px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre-line;
        max-width: 202px;
        display: -webkit-box !important;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        max-height: 65px;
      }
    }
  }
}

@media all and (min-width: 1024px) {
  .app-header  {
    .navbar-brand{
      .title {
        line-height: 30.8px !important;
        margin-top: 10px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre-line;
        max-width: 403px;
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-height: 60px;
      }
    }
  }
}