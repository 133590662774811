// Footer Page Style.

.app-footer {
  z-index: 9;
  .footerLogo {
    width: 114px;
  }
  .MuiContainer-root {
    display: flex;
    flex-direction: column;
    background: #242121;
  }

  .footer a {
    color:white;
  }
}

  #vibecheckBox {
    p {
      color:#FFFFFF;
      margin: 0;
    }

    span {
      color:#FFFFFF
    }
  }
  


@include media-breakpoint-down('sm') {
  .app-footer {
    // margin-bottom: 50px;
    .container {
      .footer-brand {
        margin-top: 1rem;
      }
    }
  }
}

@include media-breakpoint-down('lg') {
  .app-footer {
    // margin-bottom: 50px;
    .container {
      .footer-brand {
        margin-top: 1rem;
      }
    }
  }
}
