// Modal Style

.modal {
  background-color: #00000066 !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.modal-header {
  // background: none;
  .modal-title button {
    // background: none;
  }
}

.modal-body {
  // overflow-y: scroll;
}

.modal-error {
  width: 360px;
  margin: 1rem auto;

  .text-error {
    color: #0f1213;
    font-size: 14px;
  }
  .error-btn {
    background-color: #ff6a00;
    color: white;
    font-size: 16px;
  }
  .modal-footer {
    border-top: 1px solid #cdcecf;
  }
}

.modal-dialog {
  margin: 0;
}

.modal-content {
  border: 0;
  box-shadow: 0 6px 8px 0 rgba(203, 203, 203, 0.3);
  // background-color: #f8f8f8;
  height: 100%;
  border-radius: 0;
  textarea {
    border-radius: 0;
    border: 1px solid #ced4da;
  }
}

.cart--modal {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.card--info-container {
  padding: 0 !important;
}

.cart-product-title {
  font-family: Roboto;
  font-weight: 600;
  color: #0f1214;
  font-size: 28px;
  // z-index: 1;
  // position: sticky;
  // top: 0;
  // background: #f8f8f8;
}

.cart--product-desc {
  width: 100%;
  background: #f8f8f8;
  padding: 20px;
  margin-bottom: 5px;
  .text-muted {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.cart--modal-footer {
  width: 100%;
  border-radius: 0;
  height: 60px;
  font-size: 18px;
  button {
    bottom: 0;
    border-radius: 0;
    height: 100%;
    width: 100%;
    font-weight: 100;
    height: 60px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      padding-left: 10px;
      font-size: 14px;
      position: absolute;
      right: 16px;
      line-height: 16px;
    }
  }
}

.card--bottom {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 997;
  margin-top: 32px;
  background: #f8f8f8;
}

.modifier-title {
  font-family: Roboto;
  font-weight: 600;
  color: #0f1214;
  font-size: 16px;
}

.modifier-option {
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  margin-top: -1px;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 76px;
  background-color: #fff;
  position: relative;
  font-size: 16px;
}

.text-muted,
.modifier-description {
  // font-family: Roboto;
  font-weight: 400;
  color: #0f1214 !important;
  font-size: 16px;
}

.spacial {
  font-size: 16px;
  padding: 20px;
}

.item-check,
.item-uncheck {
  width: 22px;
  height: 22px;
  margin-left: 24px;
}

.modifier-price {
  font-size: 16px;
}

// Responsive Modal Style.

@include media-breakpoint-down('md') {
  .modal-dialog {
    width: 100%;
    padding: 0;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 1092px;
    margin: 1.75rem auto;
  }
}

@media (min-width: 768px) {
  .modal-error {
    margin: 20rem auto;
  }
}

@media only screen and (max-width: 1091px) {
  .cart--modal-footer {
    button {
      position: fixed;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        padding-left: 10px;
        font-size: 14px;
        position: absolute;
        right: 16px;
        line-height: 16px;
      }
    }
  }
  .modal-dialog {
    margin: 0;
    height: 100%;
    width: 100%;
  }
  .card-content {
    width: 100%;
  }
}

@media only screen and (min-width: 1092px) {
  .cart--modal {
    width: 100%;
    height: 600px;
    padding: 0;
    .card--info-container {
      flex-direction: row;
      .item-media {
        width: 600px;
        img {
          width: 600px;
          height: 600px;
        }
      }
      .card--product-info {
        background-color: #fff;
        .cart-foot {
          display: flex;
          flex-direction: row;
          position: sticky;
          bottom: 0;
          background-color: #f8f8f8;
          padding-top: 8px;
          .card--multiply-btn {
            width: 40%;
          }
          .card--bottom {
            width: 61%;
            margin-top: 0;
            .cart--modal-footer {
              height: 60px;
              button {
                font-size: 18px;
                font-weight: 100;
              }
            }
          }
        }
      }
    }
    .cart--product-desc {
      border-bottom: 1px solid #ff6a00;
      position: static;
      top: 0;
    }
    .modifiers-content {
      overflow-y: auto;
      height: 362px;
    }
  }
  .modal-info {
    width: 1092px;
  }
  .modal-dialog {
    max-width: 1092px;
  }
  .modal-open .modal {
    overflow-y: hidden;
  }
  .cart-page {
    padding: 40px 0 0 0;
  }
  .item-check,
  .item-uncheck {
    width: 16px;
    height: 16px;
  }
  .modal-dialog {
    max-width: 1092px;
  }
  .card-modal-container {
    .card-content {
      display: flex;
      flex-direction: row;
      width: 100%;
      .item-media {
        width: 600px;
        height: 600px;
        img {
          width: 600px;
        }
      }
    }
  }
  // .modal-dialog {
  //   margin: 7.75rem auto;
  // }
}


.cart-product-desc {
  display: flex;
  white-space: pre-line;
  flex-direction: column;
  overflow: auto;
  max-height: 200px;

  .text-muted {
    text-overflow: ellipsis;
    overflow-wrap: break-word
  }
  .show-less {
    width: 100%;
    display: block;
  }
  .show-more-style {
    cursor: pointer;
    font-weight: 600;
    align-items: flex-end;
    display: flex;
    margin-top: 10px;
  }
}